import { useState, useContext } from "react";
import { reject, reduce, get } from "lodash-es";

import Context from "@/layout/Context";
import { instance } from "@/lib/hooks";

import { PermissionProps, ResidentProps } from "../types";

import FormikModal from "./formik/Modal";

type Props = {
  permissions: PermissionProps;
  resident: ResidentProps;
  residents: ResidentProps[];
};

export default function ResidentMergeTool({
  resident,
  residents,
  permissions,
}: Props) {
  const { setToast } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const remainingResidents = reject(residents, ({ id }) => resident.id === id);

  const hasRemoteId = resident.remote_id;
  const isMovedIn = resident.moved_in;

  const matchingResident = reduce(
    remainingResidents,
    (matchingResident, remainingResident) => {
      if (matchingResident) {
        return matchingResident;
      }

      const firstNameMatches =
        get(remainingResident, "first_name", "").toLowerCase().trim() ===
        get(resident, "first_name", "").toLowerCase().trim();

      const lastNameMatches =
        get(remainingResident, "last_name", "").toLowerCase().trim() ===
        get(resident, "last_name", "").toLowerCase().trim();

      const phoneNumbersMatch =
        resident.phone_number &&
        remainingResident.phone_number &&
        (remainingResident.phone_number === resident.phone_number ||
          resident.phone_number.includes(remainingResident.phone_number) ||
          remainingResident.phone_number.includes(resident.phone_number));

      const emailsMatch =
        remainingResident.email &&
        resident.email &&
        remainingResident.email.toLowerCase() === resident.email.toLowerCase();

      // first name and last name match
      // phone or email matches
      // and this match has no remote_id
      if (
        firstNameMatches &&
        lastNameMatches &&
        (emailsMatch || phoneNumbersMatch) &&
        !remainingResident.remote_id
      ) {
        return remainingResident;
      }
    },
    null
  );

  if (
    !(
      permissions.merge_duplicate_residents &&
      remainingResidents.length &&
      hasRemoteId &&
      matchingResident &&
      !isMovedIn
    )
  ) {
    return null;
  }

  return (
    <div className="u-flex u-flex-justify-around u-mtop16">
      <button
        className="button buttton--sm button--orange-400"
        onClick={() => setShowModal(true)}
      >
        Merge With Matching Resident Record
      </button>

      {showModal && (
        <FormikModal
          title="Merge Resident"
          toggleModal={() => setShowModal(null)}
          onSubmit={() => {
            return instance
              .post("/residents/merge", {
                deleteResidentId: resident.id,
                updateResident: {
                  id: matchingResident.id,
                  remoteId: resident.remote_id,
                  leaseId: resident.lease_id,
                },
              })
              .then(() => {
                setToast({
                  type: "success",
                  title: "Success!",
                  message: "Resident records merged, please refresh the page",
                });
                setShowModal(false);
              })
              .catch((err) => {
                console.error(err);
                setToast({
                  type: "error",
                  title: "Error!",
                  message: "Something went wrong. Please contact engineering.",
                });
                setShowModal(false);
              });
          }}
          confirmText="Merge Resident Records"
        >
          <div>
            This resident has a matching resident record on the same unit.
          </div>
          <div>
            This resident record came from a remote PMS and the existing record
            was created manually and is attached to a user.
          </div>
          <div>
            By confirming you want to continue, this will delete this resident
            record, and move this resident's remote_id and lease_id to the other
            resident record. Do you wish to continue?
          </div>
        </FormikModal>
      )}
    </div>
  );
}
